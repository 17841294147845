body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.home-page-body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.home-container {
  width: 100%;
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.hero-section {
  position: relative;
  flex: 1; /* Take up all available space */
  width: 100%;
  overflow: hidden;
  max-height: 100vh; /* Ensure it doesn't exceed viewport height */
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.hero-overlay h1 {
  color: white;
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-overlay p {
  color: white;
  font-size: 1.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.quick-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 3rem;
  background: #f8f8f8;
}

.link-card {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.link-card:hover {
  transform: translateY(-5px);
}

.link-card h2 {
  color: #f00;
  margin-bottom: 1rem;
}

.link-card p {
  color: #666;
}

.emergency {
  background: #f00;
}

.emergency h2,
.emergency p {
  color: white;
}

.mission-section {
  padding: 4rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 4rem; /* Add space at the bottom */
}

.mission-section h2 {
  color: #333;
  margin-bottom: 1.5rem;
}

.mission-section p {
  color: #666;
  line-height: 1.6;
}

@media (max-width: 968px) {
  .hero-overlay h1 {
    font-size: 2.5rem;
  }

  .hero-overlay p {
    font-size: 1.2rem;
  }

  .quick-links {
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
  }
}

@media (max-width: 640px) {
  .hero-section {
    flex: 1; /* Use flex instead of fixed height */
  }

  .hero-overlay h1 {
    font-size: 2rem;
  }

  .quick-links {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1.5rem;
  }
}
