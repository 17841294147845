.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.contact-title {
  text-align: center;
  color: #f00;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  position: relative;
}

.contact-title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background-color: #f00;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
}

.contact-card {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.contact-card:hover {
  transform: translateY(-5px);
}

.card-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.contact-card h2 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.contact-card p {
  color: #666;
  margin: 0.5rem 0;
  line-height: 1.5;
}

.contact-card a {
  color: #f00;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-card a:hover {
  color: #d00;
  text-decoration: underline;
}

.emergency .emergency-number {
  font-size: 2.5rem;
  color: #f00;
  font-weight: bold;
  margin: 1rem 0;
}

.contact-message {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: #f8f8f8;
  border-radius: 10px;
  line-height: 1.6;
}

@media (max-width: 968px) {
  .contact-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .contact-grid {
    grid-template-columns: 1fr;
  }

  .contact-container {
    padding: 1rem;
  }

  .contact-title {
    font-size: 2rem;
  }
}
