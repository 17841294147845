.education-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.education-header {
  text-align: center;
  margin-bottom: 3rem;
}

.education-header h1 {
  color: #f00;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 1rem;
}

.education-header h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background-color: #f00;
}

.header-subtitle {
  color: #666;
  font-size: 1.2rem;
}

.safety-intro {
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
}

.safety-intro h2 {
  color: #333;
  margin-bottom: 1.5rem;
}

.safety-intro p {
  color: #666;
  line-height: 1.6;
}

.safety-tips-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.safety-card {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
}

.safety-card:hover {
  transform: translateY(-5px);
}

.tip-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.safety-card h3 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.safety-card p {
  color: #666;
  line-height: 1.5;
}

.practice-section {
  background: #f8f8f8;
  padding: 3rem;
  border-radius: 10px;
  margin-top: 3rem;
  text-align: center;
}

.practice-section h2 {
  color: #333;
  margin-bottom: 1.5rem;
}

.practice-section p {
  color: #666;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 968px) {
  .education-header h1 {
    font-size: 2rem;
  }

  .safety-tips-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .education-container {
    padding: 1rem;
  }

  .safety-tips-grid {
    grid-template-columns: 1fr;
  }

  .practice-section {
    padding: 1.5rem;
  }

  .safety-card {
    padding: 1.5rem;
  }
}
