.public-records-container {
  padding: 1.5rem;
  max-width: 1000px;
  margin: 0 auto;
}

.public-records-nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
}

.nav-button {
  background: none;
  border: none;
  font-size: 1.1rem;
  color: #333;
  padding: 0.5rem 1rem;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
}

.nav-button::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f00;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-button:hover {
  color: #f00;
}

.nav-button.active {
  color: #f00;
}

.nav-button.active::after {
  transform: scaleX(1);
}

.public-records-title {
  text-align: center;
  margin-bottom: 2rem;
  color: #f00;
  font-size: 1.8rem;
}

.documents-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 1.5rem;
  margin: 0 auto;
}

.document-card {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 180px;
  position: relative;
}

.document-card:hover {
  transform: translateY(-2px);
}

.document-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.document-info {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 40px;
}

.document-info h3 {
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
  color: #333;
}

.document-type {
  color: #666;
  font-size: 0.8rem;
}

.document-date {
  color: #888;
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

.download-button {
  background-color: #f00;
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.2s ease;
  font-size: 0.8rem;
  width: 90px;
  text-align: center;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.download-button:hover {
  background-color: #d00;
}

.records-request-section {
  margin-top: 1rem;
}

.grama-documents-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin: 1.5rem 0;
}

.contact-details {
  margin: 1.5rem 0;
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.contact-details p {
  margin: 0.5rem 0;
}

.contact-details a {
  color: #f00;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .documents-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .grama-documents-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .documents-grid,
  .grama-documents-grid {
    grid-template-columns: 1fr;
  }

  .nav-button {
    font-size: 1rem;
  }
}
