.header {
  background: rgb(179, 5, 5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  gap: 4rem;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
}

.logo {
  height: 80px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.logo-container h1 {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
}

.nav-menu {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.nav-menu ul {
  display: flex;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-menu a,
.dropdown span {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 0;
  transition: opacity 0.3s ease;
  position: relative;
}

.nav-menu a:hover,
.dropdown span:hover {
  opacity: 0.8;
}

.nav-menu a::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-menu a:hover::after {
  transform: scaleX(1);
}

/* Updated dropdown styles for vertical menu */
.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  min-width: 200px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
}

.dropdown-menu li {
  width: 100%;
  border-bottom: 1px solid #eee;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu a {
  padding: 0.75rem 1.5rem;
  display: block;
  width: 100%;
  text-align: center;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-menu a:hover {
  background-color: #f5f5f5;
  color: #c00;
}

.menu-toggle {
  display: none;
}

.hamburger {
  display: block;
  width: 24px;
  height: 2px;
  background: white;
  position: relative;
  transition: all 0.3s ease;
}

.hamburger::before,
.hamburger::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: white;
  transition: all 0.3s ease;
}

.hamburger::before {
  top: -6px;
}

.hamburger::after {
  bottom: -6px;
}

@media (max-width: 968px) {
  .header-container {
    justify-content: space-between;
    padding: 1rem;
    gap: 2rem;
  }

  .logo-container h1 {
    display: none;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: auto;
  }

  .nav-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 1000;
  }

  .nav-menu.active {
    display: block;
  }

  .nav-menu ul {
    flex-direction: column;
    gap: 1rem;
  }

  .nav-menu a,
  .dropdown span {
    color: #333;
  }

  .nav-menu a::after {
    background-color: #c00;
  }

  .dropdown-menu {
    position: static;
    opacity: 1;
    visibility: visible;
    transform: none;
    box-shadow: none;
    padding-left: 1rem;
    width: 100%;
  }
}

@media (max-width: 640px) {
  .header-container {
    padding: 0.5rem 1rem;
  }

  .logo {
    height: 60px;
  }

  .logo-text {
    display: none;
  }
}
