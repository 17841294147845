/* Footer.css */

footer {
  position: fixed; /* This is key to keep the footer fixed */
  bottom: 0; /* Position it at the bottom of the viewport */
  left: 0; /* Position it at the left edge of the viewport */
  width: 100%; /* Make it full width */
  background-color: rgb(179, 5, 5); /* Red background */
  color: #fff; /* White text */
  padding: 1rem 0;
  text-align: center;
}

footer p {
  margin: 0;
}

footer a {
  color: #fff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
