.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.about-header {
  text-align: center;
  margin-bottom: 3rem;
}

.about-header h1 {
  color: #f00;
  font-size: 2.5rem;
  position: relative;
  padding-bottom: 1rem;
}

.about-header h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background-color: #f00;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.about-section {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-section h2 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
}

.about-section p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.contact-info {
  background: #f8f8f8;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 1rem;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 1.5rem;
}

.stat-item {
  text-align: center;
  padding: 1.5rem;
  background: #f8f8f8;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-item h3 {
  color: #f00;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.mission-section {
  background: #f8f8f8;
  padding: 3rem;
  border-radius: 10px;
  text-align: center;
}

.mission-section h2 {
  color: #333;
  margin-bottom: 2rem;
}

blockquote {
  font-style: italic;
  color: #666;
  font-size: 1.2rem;
  line-height: 1.8;
  position: relative;
  padding: 0 2rem;
}

@media (max-width: 968px) {
  .stats-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .about-header h1 {
    font-size: 2rem;
  }
}

@media (max-width: 640px) {
  .about-container {
    padding: 1rem;
  }

  .stats-container {
    grid-template-columns: 1fr;
  }

  .mission-section {
    padding: 2rem 1rem;
  }

  blockquote {
    padding: 0 1rem;
    font-size: 1rem;
  }
}
