/* Permits Page Styles */

#Permits {
  text-align: center;
  padding: 5px;
  background-color: #f0f0f0;
  max-width: 1500px;
  margin: 0 auto;
  padding-bottom: 3em;
}

#Permits h2 {
  color: #c00;
  font-size: 2em;
  margin-bottom: 1em;
}

#Permits p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 1em;
}

#Permits ul {
  list-style: none;
  padding: 0;
  margin-bottom: 2em;
}

#Permits li {
  margin-bottom: 1em;
}

#Permits a {
  color: #c00;
  text-decoration: none;
}

#Permits a:hover {
  text-decoration: underline;
}

.btn-color-label:hover,
.btn-check:active + .btn-color-label,
.btn-check:checked + .btn-color-label,
.btn-color-label.active,
.btn-color-label.dropdown-toggle.show,
.btn-color-label:active {
  background-color: #c00 !important;
  color: white;
  border-color: #c00;
}

.btn-group {
  color: #c00;
  border-color: #c00;
}
