body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: auto; /* Allow vertical scrolling */
}

.recruitment-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  /* Make sure this container is scrollable */
  overflow-y: auto;
  height: auto;
  min-height: 100vh;
}

.recruitment-header {
  text-align: center;
  margin-bottom: 3rem;
}

.recruitment-header h1 {
  color: #f00;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 1rem;
}

.recruitment-header h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background-color: #f00;
}

.header-subtitle {
  color: #666;
  font-size: 1.2rem;
}

.career-intro {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
}

.career-intro h2 {
  color: #333;
  margin-bottom: 1rem;
}

.career-intro p {
  color: #666;
  line-height: 1.6;
}

.positions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.position-card {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.position-card:hover {
  transform: translateY(-5px);
}

.position-header {
  margin-bottom: 1.5rem;
}

.position-header h3 {
  color: #333;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.position-badges {
  display: flex;
  gap: 1rem;
}

.badge {
  padding: 0.3rem 0.8rem;
  border-radius: 15px;
  font-size: 0.8rem;
}

.badge.type {
  background: #e6f3ff;
  color: #0066cc;
}

.badge.status {
  background: #eb2525;
  color: #f8fdf8;
}

.position-description {
  color: #666;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.requirements-section h4 {
  color: #333;
  margin-bottom: 1rem;
}

.requirements-section ul {
  list-style: none;
  padding-left: 0;
}

.requirements-section li {
  color: #666;
  padding: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.requirements-section li::before {
  content: "•";
  color: #f00;
  position: absolute;
  left: 0;
}

.position-footer {
  margin-top: 2rem;
  text-align: center;
}

.apply-button {
  background-color: #f00;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.apply-button:hover {
  background-color: #d00;
}

.benefits-section {
  margin-top: 4rem;
  margin-bottom: 4rem; /* Add bottom margin */
  text-align: center;
  padding-bottom: 2rem; /* Add bottom padding */
}

/* Benefits Section */

/* Benefits Section - replace only this section */
.benefits-section {
  margin-top: 4rem;
  margin-bottom: 4rem;
  text-align: center;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.benefits-section h2 {
  color: #333;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.benefit-card {
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%; /* Ensures all cards have the same height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.benefit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.benefit-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #f00; /* Match your brand color */
}

.benefit-card h3 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.benefit-card p {
  color: #666;
  line-height: 1.5;
}

@media (max-width: 968px) {
  .benefits-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .benefits-grid {
    grid-template-columns: 1fr;
  }

  .benefit-card {
    padding: 1.5rem;
  }
}
