.resources-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.resources-header {
  text-align: center;
  margin-bottom: 3rem;
}

.resources-header h1 {
  color: #f00;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 1rem;
}

.resources-header h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background-color: #f00;
}

.resources-header p {
  color: #666;
  font-size: 1.2rem;
}

.stations-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 2rem 0;
}

.station-card {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.station-card:hover {
  transform: translateY(-5px);
}

.station-header {
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f0f0f0;
}

.station-header h2 {
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.location {
  color: #666;
  font-size: 1rem;
}

.equipment-list h3 {
  color: #f00;
  margin-bottom: 1rem;
}

.equipment-list ul {
  list-style: none;
  padding: 0;
}

.equipment-list li {
  color: #666;
  padding: 0.5rem 0;
  border-bottom: 1px solid #f0f0f0;
}

.equipment-list li:last-child {
  border-bottom: none;
}

.resources-footer {
  margin-top: 3rem;
  text-align: center;
}

.info-box {
  background: #f8f8f8;
  padding: 2rem;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
}

.info-box h3 {
  color: #333;
  margin-bottom: 1rem;
}

.info-box p {
  color: #666;
  line-height: 1.6;
}

@media (max-width: 968px) {
  .stations-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .resources-header h1 {
    font-size: 2rem;
  }
}

@media (max-width: 640px) {
  .resources-container {
    padding: 1rem;
  }

  .stations-grid {
    grid-template-columns: 1fr;
  }

  .station-card {
    padding: 1.5rem;
  }

  .resources-header p {
    font-size: 1rem;
  }
}
