.news-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.news-header {
  text-align: center;
  margin-bottom: 3rem;
}

.news-header h1 {
  color: #f00;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 1rem;
}

.news-header h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background-color: #f00;
}

.header-subtitle {
  color: #666;
  font-size: 1.2rem;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.news-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.news-card:hover {
  transform: translateY(-5px);
}

.news-icon {
  font-size: 2rem;
  padding: 1.5rem;
  background: #f8f8f8;
  text-align: center;
}

.news-content {
  padding: 1.5rem;
  flex: 1;
}

.news-type {
  display: inline-block;
  background: #f00;
  color: white;
  padding: 0.3rem 0.8rem;
  border-radius: 15px;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.news-content h2 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.news-content p {
  color: #666;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.news-meta {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.news-date {
  color: #888;
  font-size: 0.9rem;
}

.news-footer {
  padding: 1rem;
  background: #f8f8f8;
  text-align: center;
  border-top: 1px solid #eee;
  margin-top: auto;
}

.view-button {
  background-color: #f00;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
  display: inline-block;
}

.view-button:hover {
  background-color: #d00;
}

@media (max-width: 968px) {
  .news-header h1 {
    font-size: 2rem;
  }

  .news-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .news-container {
    padding: 1rem;
  }

  .news-grid {
    grid-template-columns: 1fr;
  }

  .news-footer {
    padding: 0.75rem;
  }

  .view-button {
    width: 100%;
    text-align: center;
  }
}
